import React from 'react';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { PrivateUpcoming } from './components/PrivateUpcoming/PrivateUpcoming0';
import { PrivateSchedLsn } from './components/PrivateSchedLsn/PrivateSchedLsn0';
import { Messages } from './components/Messages';
import { DiscoverTeachers } from './components/DiscoverTeachers';
import { GroupLessons } from './components/GroupLessons';

// entire home page layout
export const LayoutPosttrial = () => (
  <Grid2Ct>
    {/* upcoming private lessons, schedule a private lesson */}
    <Grid2 xs={12}>
      <Grid2Ct>
        <PrivateUpcoming />
        <PrivateSchedLsn />
      </Grid2Ct>
    </Grid2>

    {/* TODO: notification - unread messages */}
    {/* group classes, discover teachers */}
    <Grid2 xs={12}>
      <Grid2Ct>
        <GroupLessons />
        <DiscoverTeachers />
      </Grid2Ct>
    </Grid2>

    {/* messages */}
    <Grid2 xs={12}>
      <Grid2Ct>
        <Messages />
      </Grid2Ct>
    </Grid2>
  </Grid2Ct>
);

import React, {
  useCallback, useState,
} from 'react';
import { DateTime } from 'luxon';
import {
  Button, Grid2Ct, Grid2, Dialog, Ty, Box,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { sitedata } from '../../../../../utils/sitedata';
import { setHasInteractedTrue } from '../../../../../common/relay/clientschema/relayappsettings/groupclassFunctionality';

interface Props {
  // boolean. true if the student has this lesson reserved
  isReserved: any
  // lesson properties from our db
  lessonDetails: any
  // current time, a luxon object
  currentTime: any
  // function for reserving a reservation
  createReservation: any
  // gets set to the uuid of the lesson that user is trying to reserve a seat in
  // empty string if no lesson is currently being reserved
  reserveButtonInFlight: string
  // function for canceling a reservation
  cancelReservation: any
  // gets set to the uuid of the lesson that user is trying to cancel their reservation for
  // empty string if no lesson is currently being canceled
  cancelresvButtonInFlight: string
  // whether or not the user has already reserved the max number of class that
  // they are allowed to reserve
  maxLessonsReserved: boolean
}

// displays either "join now!", "reserve", "class full", "ending soon", or nothing
// button for an individiual lesson. as well as a "cancel" button
export const Step3DisplayButton = ({
  isReserved,
  lessonDetails,
  currentTime,
  createReservation,
  reserveButtonInFlight,
  cancelReservation,
  cancelresvButtonInFlight,
  maxLessonsReserved,
}: Props) => {
  // #region general

  // classroom url
  const classroomUrl = `${sitedata.url.app.groupclassroomDt.pathFull}${lessonDetails.uuid}`;

  // #endregion

  // #region modals

  // join now modal
  const [modalStateJoinclass, setModalStateJoinclass] = useState<boolean>(false);
  const handleModalCloseJoinclass = () => {
    setModalStateJoinclass(false);
  };

  // join soon modal
  const [modalStateJoinsoonclass, setModalStateJoinsoonclass] = useState<boolean>(false);
  const handleModalCloseJoinsoonclass = () => {
    setModalStateJoinsoonclass(false);
  };

  // ending soon modal
  const [modalStateEndingsoon, setModalStateEndingsoon] = useState<boolean>(false);
  const handleModalOpenEndingsoon = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateEndingsoon(true);
  };
  const handleModalCloseEndingsoon = () => {
    setModalStateEndingsoon(false);
  };

  // class full modal
  const [modalStateClassfull, setModalStateClassfull] = useState<boolean>(false);
  const handleModalOpenClassfull = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateClassfull(true);
  };
  const handleModalCloseClassfull = () => {
    setModalStateClassfull(false);
  };

  // #endregion

  /* Display the "join now!" or "reserve" or "class full" or "ending soon" buttons, and also
      a "cancel" button.
      This runs whenever currentTime changes (about every 5 seconds), or lesson details change,
      or maxLessonsReserved chagnes, potentially updating the the button.
      For example, if a lesson is a little more than 5 minutes from now, a "reserve"
      button will be shown; but once the lesson starts <= 5 minutes a "join now!" button is shown
  */
  const LessonButtonStatus = useCallback(({
    isResd,
    lesson,
    currentTme,
    createRes,
    rsvButtonInFlight,
    cnclresvButtonInFlight,
    maxLsnsReserved,
  }: any) => {
    // #region data

    // lesson start and end luxon objects
    const lsnStartTime = DateTime.fromISO(lesson.starts_at);
    const lsnEndTime = DateTime.fromISO(lesson.ends_at);

    // number of seconds until the lesson starts. if lesson has already started
    // this will be negative. note this is a decimal
    const startTimeDiffSeconds: any = lsnStartTime.diff(currentTme, ['seconds']);
    const lessonStartsInXSeconds = startTimeDiffSeconds.toObject().seconds;

    // number of minutes until the lesson ends. if lesson has already ended
    // this will be negative. note this is a decimal
    const endTimeDiffSeconds: any = lsnEndTime.diff(currentTme, ['seconds']);
    const lessonEndsInXSeconds = endTimeDiffSeconds.toObject().seconds;

    // #endregion

    // #region student has this lesson reserved

    /* Student has this lesson reserved
        At the top, we'll show a button, either: join later, join soon, or join now
        And we'll potentially show a button a "cancel" button
    */
    if (isResd) {
      // which "join" button should we show?
      //  1 = join now (lesson is coming up in less than 5 minutes)
      //  2 = join soon (lesson is coming up in less than 20 minutes)
      //  3 = join later (lesson is coming up in more than 20 minutes)
      //  0 = no button. only unlikely case is if the lesson has already ended and just for a second
      //    or two the button might still show
      let whichJoin = 0;
      if (lessonStartsInXSeconds < 300 && lessonEndsInXSeconds > 0) {
        whichJoin = 1;
      } else if (lessonStartsInXSeconds < 1200 && lessonEndsInXSeconds > 0) {
        whichJoin = 2;
      } else if (lessonStartsInXSeconds >= 1200 && lessonEndsInXSeconds > 0) {
        whichJoin = 3;
      }

      // user is allowed to cancel if they they have a reservation and
      // the lesson is starting in more than 10 seconds. we also create bottom margin on the
      // join button, if both it and the cancel button are being displayed
      let canCancel = false;
      let joinBottomMargin = {};
      const cancelAllowedUntil = lsnStartTime.minus({ seconds: 10 });
      if (currentTime < cancelAllowedUntil) {
        canCancel = true;
        joinBottomMargin = {
          mb: 3
        };
      }

      // loading and disabled if the reservation mutation is in flight for this class
      let isLoading = false;
      let disabled = false;
      if (cnclresvButtonInFlight === lesson.uuid) {
        isLoading = true;
        disabled = true;
      }

      // conditionally display the buttons
      if (whichJoin !== 0 || canCancel) {
        return (
          <ButtonWrapper>
            {/* join now! button */}
            { whichJoin === 1 ? (
              <Button
                color="accentGreen1"
                size="medium"
                fullWidth
                cp={{
                  sx: { ...joinBottomMargin }
                }}
                onClick={() => {
                  // set relay client schema groupClass.hasInteracted = true when button clicked
                  // so that the user doesn't have to click the "enter class" button on next screen
                  setHasInteractedTrue();
                  setModalStateJoinclass(true);
                }}
              >
                Join Now!
              </Button>
            ) : <div /> }

            {/* join soon button */}
            { whichJoin === 2 ? (
              <Button
                color="accentGreen1"
                size="medium"
                fullWidth
                cp={{
                  sx: { ...joinBottomMargin }
                }}
                onClick={() => {
                  setHasInteractedTrue();
                  setModalStateJoinsoonclass(true);
                }}
              >
                Join Soon
              </Button>
            ) : <div /> }

            {/* join later button */}
            { whichJoin === 3 ? (
              <Button
                color="accentGreen1"
                size="medium"
                fullWidth
                cp={{
                  sx: { ...joinBottomMargin }
                }}
                // note that we show the same modal as the "join soon" button; the text in it
                // is clear to the user that they can't join yet
                onClick={() => {
                  setHasInteractedTrue();
                  setModalStateJoinsoonclass(true);
                }}
              >
                Join Later
              </Button>
            ) : <div /> }

            {/* cancel button */}
            { canCancel ? (
              <Button
                color="accentRed1"
                size="medium"
                fullWidth
                isLoading={isLoading}
                disabled={disabled}
                onClick={() => cancelReservation(lesson.uuid)}
              >
                Cancel
              </Button>
            ) : <div /> }
          </ButtonWrapper>
        );
      }

      // otherwise return nothing, no button should be displayed
      return (<div />);
    }

    // #endregion

    // #region student does NOT have this lesson reserved

    /* We'll show different buttons in different situations:
          - class full
          - ending soon
          - join now!
          - reserve
          - nothing

        IMPORTANT NOTE: the *order* in which these if statements are listed
        is vital. For example, we must first check if the lesson is full; if the code
        gets past that if statement, it means the lesson is not full
    */

    // if the user has reached the max number of lessons they are allowed to reserve,
    // we wont show them any buttons. the only buttons they will see are buttons on the
    // lessons they have reserved
    if (maxLsnsReserved) {
      return (<div />);
    }

    // lesson is full, so we cant show a join or reserve button
    if (lesson.num_reservations >= lesson.max_students) {
      return (
        <ButtonWrapper>
          <Button
            color="accentOrange1"
            variant="contained"
            size="medium"
            fullWidth
            onClick={handleModalOpenClassfull}
          >
            Class Full
          </Button>
        </ButtonWrapper>
      );
    }

    // lesson is ending in less than 5 minutes, so student can't reserve or join. note we
    // add a few seconds since, if they reserve, the api call takes a few seconds
    if (lessonEndsInXSeconds <= 306) {
      return (
        <ButtonWrapper>
          <Button
            color="accentOrange1"
            size="medium"
            fullWidth
            onClick={handleModalOpenEndingsoon}
          >
            Ending Soon
          </Button>
        </ButtonWrapper>
      );
    }

    // lesson starts in less than 5 minutes, so we can show the "join now" button
    if (lessonStartsInXSeconds < 300) {
      return (
        <ButtonWrapper>
          <Button
            color="accentGreen1"
            size="medium"
            fullWidth
            onClick={() => {
              // set relay client schema groupClass.hasInteracted = true when button clicked
              setHasInteractedTrue();
              setModalStateJoinclass(true);
            }}
          >
            Join Now!
          </Button>
        </ButtonWrapper>
      );
    }

    // lesson starts in less than 3 hours, so we can show a "reserve" button. show
    // loading and disabled if the reservation mutatiln is in flight for this class
    let isLoading = false;
    let disabled = false;
    if (rsvButtonInFlight === lesson.uuid) {
      isLoading = true;
      disabled = true;
    }
    if (lessonStartsInXSeconds < 10800) {
      return (
        <ButtonWrapper>
          <Button
            color="accentPurple1"
            size="medium"
            fullWidth
            isLoading={isLoading}
            disabled={disabled}
            onClick={() => createRes(lesson.uuid)}
          >
            Reserve
          </Button>
        </ButtonWrapper>
      );
    }

    // #endregion

    // just in case code gets here, return blank. probably should not get here though?
    // not 100% sure, need to consider logging
    return (
      <div />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, lessonDetails, maxLessonsReserved]);

  return (
    <>
      <LessonButtonStatus
        isResd={isReserved}
        lesson={lessonDetails}
        currentTme={currentTime}
        createRes={createReservation}
        rsvButtonInFlight={reserveButtonInFlight}
        cancelRes={cancelReservation}
        cnclresvButtonInFlight={cancelresvButtonInFlight}
        maxLsnsReserved={maxLessonsReserved}
      />

      {/* join now! modal */}
      <Dialog
        isOpen={modalStateJoinclass}
        onClose={handleModalCloseJoinclass}
        width="xs"
        color="accentGreen1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty align="center">
              Ready to join the class?
            </Ty>
            <Box display="flex" justifyContent="center">
              <Button
                color="accentGreen1"
                size="medium"
                component={NavLink}
                to={classroomUrl}
                cp={{ sx: { mt: 2, mb: 2 } }}
              >
                Yes, Let&apos;s Go!
              </Button>
            </Box>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* join soon modal */}
      <Dialog
        isOpen={modalStateJoinsoonclass}
        onClose={handleModalCloseJoinsoonclass}
        width="xs"
        color="accentGreen1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty align="center">
              This classroom isn&apos;t quite ready yet &mdash; come back once your
              class is going to start within 5 minutes and you&apos;ll be able to join!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* ending soon modal */}
      <Dialog
        isOpen={modalStateEndingsoon}
        onClose={handleModalCloseEndingsoon}
        width="xs"
        color="accentOrange1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              This class is ending in less than 5 minutes and you don&apos;t have a
              reserved seat in it, so unfortunately you cannot join.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* class full modal */}
      <Dialog
        isOpen={modalStateClassfull}
        onClose={handleModalCloseClassfull}
        width="xs"
        color="accentOrange1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              This class has filled up. If a student happens to cancel their reserved seat though
              you will see the page update automatically, showing you the Reserve button
              so that you can reserve a seat!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// #region sub-components

const ButtonWrapper = ({ children }: any) => (
  <Grid2 xs={12} sm={6} smOffset={3} md={4} mdOffset={0} lg={4} xl={3}>
    {children}
  </Grid2>
);

// #endregion

import React from 'react';
import {
  CardStandard, Ty, Grid2Ct, Grid2, IcSvgList, Link,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { GridEl } from './Common';
import { sitedata } from '../../../../../../utils/sitedata';

export const GroupLessons = () => {
  // link to find a teacher page
  const clickableTypeObjGroupclasses = {
    to: sitedata.url.app.grouplsnUpcomingDt.pathFull,
    component: NavLink,
  };

  return (
    <>
      {/* main component */}
      <GridEl>
        <CardStandard
          titleText="Group Classes"
          titleIcon={IcSvgList.group1}
          color="accentBlue1"
          cp={{ sx: { width: '100%' } }}
          clickableLink1={{ ...clickableTypeObjGroupclasses }}
        >
          <Link
            to={sitedata.url.app.grouplsnUpcomingDt.pathFull}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty removeMb>
                  Join a teacher and small group of other students!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </GridEl>
    </>
  );
};

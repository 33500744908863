import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import {
  CardSmallColor, Grid2Ct, Grid2, Box, Dialog, Ty, CardSmallColorIcon, IcSvgList, Ic,
} from '@languageconvo/wcl';
import { calculateAndSetDaysSelector, DaysSelector } from '../GroupSchedule';

interface Props {
  daysSelector: DaysSelector
  setDaysSelector: any
}

// day selector and info
export const Header = ({ daysSelector, setDaysSelector }: Props) => {
  // #region general

  // allDays holds the text and icons for each of the 3 days that we'll display
  // in the modal
  const [allDays, setAllDays] = useState<any>();
  // selectedDayText and selectedDayIcon are the text and icon for the day the
  // user currently has selected
  const [selectedDayText, setSelectedDayDisplay] = useState<any>();
  const [selectedDayIcon, setSelectedDayIcon] = useState<any>();

  useEffect(() => {
    const day2Text = DateTime.fromMillis(daysSelector.day2.startTs).toLocaleString({
      weekday: 'long',
    });
    const day3Text = DateTime.fromMillis(daysSelector.day3.startTs).toLocaleString({
      weekday: 'long',
    });
    const daysDisplay = {
      day1: {
        text: 'Today',
        icon: 'sun1_v2',
      },
      day2: {
        text: day2Text,
        icon: 'sun2',
      },
      day3: {
        text: day3Text,
        icon: 'sun3_v2',
      }
    };
    setAllDays((prevState: any) => ({
      ...prevState,
      ...daysDisplay,
    }));

    if (daysSelector.selectedDay.startTs === daysSelector.day1.startTs) {
      setSelectedDayDisplay(daysDisplay.day1.text);
      setSelectedDayIcon(daysDisplay.day1.icon);
    } else if (daysSelector.selectedDay.startTs === daysSelector.day2.startTs) {
      setSelectedDayDisplay(daysDisplay.day2.text);
      setSelectedDayIcon(daysDisplay.day2.icon);
    } else if (daysSelector.selectedDay.startTs === daysSelector.day3.startTs) {
      setSelectedDayDisplay(daysDisplay.day3.text);
      setSelectedDayIcon(daysDisplay.day3.icon);
    } else {
      // log error, should never happen
      Sentry.captureException(
        new Error('daysSelector error in in Header.tsx'),
        {
          extra: {
            startTs: daysSelector.selectedDay.startTs,
            day1StartTs: daysSelector.day1.startTs,
            day2StartTs: daysSelector.day2.startTs,
            day3StartTs: daysSelector.day3.startTs,
          }
        }
      );
    }
  }, [daysSelector, selectedDayText, selectedDayIcon]);

  // #endregion

  // #region today/tomorrow/next day modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    // every time the modal opens, we'll check to ensure that the timestamps for
    // day1, day2, day3 in our daysSelector is correct. mainly for the use
    // case that a new day has started but for some reason our code which checks that
    // has not run yet
    calculateAndSetDaysSelector(0, daysSelector, setDaysSelector);
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // handle clicks on the different days. day 1 is today, day 2 is  tomorrow, day 3 is
  // 2 days in the future
  const clickSelectDay1 = () => {
    calculateAndSetDaysSelector(1, daysSelector, setDaysSelector);
    handleModalClose();
  };
  const clickSelectDay2 = () => {
    calculateAndSetDaysSelector(2, daysSelector, setDaysSelector);
    handleModalClose();
  };
  const clickSelectDay3 = () => {
    calculateAndSetDaysSelector(3, daysSelector, setDaysSelector);
    handleModalClose();
  };

  // #endregion

  // #region info modal

  // modal state. true=open, false=closed. closed by default
  const [modalStateInfo, setModalStateInfo] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpenInfo = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateInfo(true);
  };

  // modal close action
  const handleModalCloseInfo = () => {
    setModalStateInfo(false);
  };

  // #endregion

  return (
    <>
      {/* daypicker, and info card */}
      <Grid2Ct sx={{ mb: 2 }}>
        {/* day picker */}
        <Grid2 xs={9}>
          <CardSmallColor
            smallerfont
            icon={selectedDayIcon}
            text={selectedDayText}
            color="cardstandard"
            hovercursor="pointer"
            onClick={handleModalOpen}
            // set the width otherwise it gets really wide
            cp={{ sx: { maxWidth: '300px' }, 'data-intercom': 'groupscheddaypick' }}
          />
        </Grid2>

        {/* info card */}
        <Grid2 xs={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CardSmallColorIcon
              icon={IcSvgList.question1}
              color="cardstandard"
              hovercursor="pointer"
              onClick={handleModalOpenInfo}
              cp={{ 'data-intercom': 'groupschedinfo' }}
            />
          </Box>
        </Grid2>
      </Grid2Ct>

      {/* today/tomorrow/next day modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="xs"
        color="accentGreen1"
      >
        <DayModalContents
          allDays={allDays}
          clickSelectDay1={clickSelectDay1}
          clickSelectDay2={clickSelectDay2}
          clickSelectDay3={clickSelectDay3}
        />
      </Dialog>

      {/* info modal */}
      <Dialog
        isOpen={modalStateInfo}
        onClose={handleModalCloseInfo}
        width="xl"
        color="accentBlue1"
      >
        <InfoModalContents />
      </Dialog>
    </>
  );
};

// #region sub-components

// contents of the day selection modal
const DayModalContents = ({
  allDays, clickSelectDay1, clickSelectDay2, clickSelectDay3
} : any) => (
  <Grid2Ct sx={{ mb: 2 }}>
    {/* text */}
    <Grid2 xs={12} sx={{ mt: 2 }}>
      <Ty align="center">Select the day you want to view!</Ty>
    </Grid2>

    {/* today */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day1.icon}
          text={allDays.day1.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay1}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>

    {/* next day */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day2.icon}
          text={allDays.day2.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay2}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>

    {/* 2 days from now */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day3.icon}
          text={allDays.day3.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay3}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>
  </Grid2Ct>
);

const InfoModalContents = () => (
  <Grid2Ct>
    <Grid2 xs={12} sm={10} smOffset={1}>
      {/* page automatically updates */}
      <Ty v="h2New">
        <Ic iconName="square-info" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;Page Automatically Updates
      </Ty>
      <Ty>
        There&apos;s no need to reload this page, it will automatically update,
        and in fact you will see updates faster if you do not reload the page.
        As classes come and go, as you and other students reserve and cancel seats in classes,
        the page will show these updates instantly. It will even automatically update at
        midnight when a new day begins!
      </Ty>

      {/* dont worry about class topic */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="language" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Important idea - don&apos;t worry too much about the class topic!
      </Ty>
      <Ty>
        Each class has a topic, and you may think to yourself that you need to carefully consider
        which topics you study. What research, and our collective experience as language teachers
        shows is that you will do best if you <strong>practice and use</strong> the language you
        want to learn! So don&apos;t worry about trying to cover every specific topic in some
        predefined order; instead, join classes so that you hear and use the language! Just jump in.
        &nbsp;<strong>Practice using the language.</strong>
      </Ty>

      {/* do consider the level */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="chart-line" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;Important idea - DO consider the level!
      </Ty>
      <Ty>
        Every class has a level listed: Beginner, Beginner+, Intermediate, Intermediate+, Advanced,
        or Advanced+
        <br /><br />
        Try to join classes that are at or below your level. We know this is not a perfect system
        yet; we&apos;re working on making this more clear with our new Progress system. For now,
        consider Intermediate level to mean you are able to hold some conversation, whether it be
        relatively simple conversation (&quot;Intermediate&quot;) or more complex conversation
        (&quot;Intermediate+&quot;). Beginner levels are of course easier than that, and advanced
        levels are more difficult.
      </Ty>

      {/* button: join now / soon / later */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="desktop" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;&quot;Join Now! / Soon / Later&quot; Buttons
      </Ty>
      <Ty>
        Click the &quot;Join Now!&quot; button to join a class; you&apos;ll see this button
        when a class is starting in less than 5 minutes or has already started. It is ok
        to join a class that has already begun!
        <br /><br />
        You will also see &quot;Join Soon&quot; and &quot;Join Later&quot; buttons. These are shown
        when you have reserved a seat in a class, but the class is not starting within the next 5
        minutes. Not to  worry, the button will automatically change to &quot;Join Now!&quot; once
        the class is within 5 minutes  of starting.
      </Ty>

      {/* button: reserve */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="party-horn" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;&quot;Reserve&quot; Button
      </Ty>
      <Ty>
        If there is an upcoming class that looks interesting to you, you can reserve a seat
        in that class up to 3 hours in advance (note: you can cancel your reservation up until
        10 seconds before the class starts). Just click the &quot;Reserve&quot; button to do so.
        If reserving more in advance is important to you, note that in the near future we&apos;ll
        make a (low cost!) add-on to subscriptions that will allow you to reserve more
        classes further into the future.
        <br /><br />
        Remember though, you can join a class without reserving a seat in advance! Once a class
        is starting within 5 minutes you will see the &quot;Join Now&quot; button even if you did
        not reserve a seat. Click it to join the class!
      </Ty>

      {/* button: ending soon */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="circle-xmark" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;&quot;Ending Soon&quot; Button
      </Ty>
      <Ty>
        This means the class is ending soon, so new students can no longer join the class.
      </Ty>

      {/* button: class full */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="user-xmark" iconStyle="duotone" color="accentYellow1" />&nbsp;&nbsp;&quot;Class Full&quot; Button
      </Ty>
      <Ty>
        All seats in this class have already been reserved. Remember though, another student could
        cancel their reserved seat at any time which would open a space for you. Also keep in mind
        that the page will <strong>automatically</strong> update if that happens, you do not need
        to refresh the page to see updates (and in fact you will see updates faster if you do not
        refresh the page).
      </Ty>

      {/* button: none */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="seal-question" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;No Button
      </Ty>
      <Ty>
        If you don&apos;t see a button next to a class, that is normal! It means one of two
        things. One, you already have a seat in a different class reserved. Recall that you
        can only have one class reserved at a time, so we hide the &quot;Reserve&quot; button
        for other classes.
        <br /><br />
        Two, it could mean that the class is starting more than 3 hours from now. Classes that
        start more than 3 hours from now cannot be reserved yet.
      </Ty>

      {/* class format, how works */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="users" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Group Class Format, How This Works
      </Ty>
      <Ty>
        Welcome to one of the best, most affordable ways to improve your foreign language skills!
        Group classes are just what they sound like: you, a professional language teacher, and a few
        other students. We&apos;re experimenting with different class formats, but for now most
        classes will consist of your teacher teaching a topic for the first half of the class, and
        then practicing what was learned during the second half of the class by conversing with
        the other students in the class.
        <br /><br />
        Note: during the portion of the class where you only see the teacher (the portion during
        which the teacher is teaching you a topic), other students cannot
        see or hear you &mdash; the teacher cannot see or hear you either. During the part of the
        class where you get to chat with other students and practice what you learned, the other
        students can of course hear you but they cannot see you. Your camera is not used.
      </Ty>

      {/* no camera, mic is important */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="microphone-stand" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;No Camera, But Microphone is Very Important
      </Ty>
      <Ty>
        You won&apos;t be able to <em>see</em> the other students in the class; we
        do not use your camera. Your microphone is very important though, which is why you will see
        that we have it prominently displayed on the page. For the other students in the class
        to hear you well, it&apos;s important you have a decent microphone and that you check it
        is the one that is being used by our classroom. You have probably experienced online calls
        where one person had a bad microphone; it makes for a poor experience for everyone involved.
      </Ty>

      {/* how many other students */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="circle-user" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;How many other students?
      </Ty>
      <Ty>
        During the part of the class where you chat with other students, you will usually
        be in a group with three other people. In total there can be a maximum of 8 students in
        a class but your teacher will split this into two separate groups of 4 people each.
        Small groups allow you to get more practice! Your teacher will move between the two
        groups, helping the conversation/practice along.
        <br /><br />
        If only 5 students total are in the class, your teacher may elect to have one group of
        5, or split into two groups (one group of 3, one group of 2).
      </Ty>

      {/* this is new, please help */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="face-smiling-hands" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;This is new, please help us!
      </Ty>
      <Ty>
        This is a completely new way to learn a language &mdash; a low cost monthly subscription
        that allows you to jump in and learn/practice your language skills <em>whenever</em> you
        have a little time. Please give us ideas, tell us what works well, what doesn&apos;t, we
        would really appreciate it and you will contribute to making this a better learning
        experience. Go to the &quot;More&quot; page to send us a quick chat message or email.
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// #endregion

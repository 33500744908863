import React, { Dispatch, SetStateAction, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useSubscription } from 'react-relay';
import { GetGroupClassroomSubscription } from '../relay/GetGroupClassroom';
import { GetGroupClassroomSubscription as GetGroupClassroomSubscriptionType } from '../relay/__generated__/GetGroupClassroomSubscription.graphql';

interface Props {
  setGroupClassroomFragmentRef: Dispatch<SetStateAction<any>>;
}

/** here we are creating a graphql subscription to the subs table, particularly the
 * groupcl column of that table. that column tells us whether or not the user is
 * subscribed. one important note, this subscription is not guaranteed to resolve
 * before the page code down the chain of loading has run, so when using this you
 * **cannot** guarantee the value of groupcl will exist in your page code
 */
export const GetGroupClassRoomSubsTableSubscription = ({ setGroupClassroomFragmentRef }: Props) => {
  // #region for `GetUnreadMessagesSubscription` subscription 

  // subsctiption configurations for GetUnreadMessagesSubscription
  const GetGroupClassroom: any = useMemo(() => ({
    // Define a subscription object
    subscription: GetGroupClassroomSubscription,
    // a callback that is executed when a subscription payload is received
    onNext: (res: any) => {
      // eslint-disable-next-line no-console
      console.log('res.subs_connection.edges[0].node', res.subs_connection.edges[0].node);

      setGroupClassroomFragmentRef(res.subs_connection.edges[0].node);
    },

    // a callback that is executed when the subscription errors.
    onError: (err: any) => {
      // eslint-disable-next-line no-console
      console.log('err in Group Classroom Subs table Subscription', err);
      Sentry.captureException(err);
    },

    // a callback that is executed when the server ends the subscription
    onCompleted: () => {
      Sentry.captureMessage('UIS Get Group Classroom Subs table Subsription completed!');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // executing the subscription for checking if the user subscribed group classroom or not
  useSubscription<GetGroupClassroomSubscriptionType>(GetGroupClassroom);

  // #endregion

  return (
    <div />
  );
};

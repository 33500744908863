import React, {
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import * as Sentry from '@sentry/react';
import {
  commitLocalUpdate, useLazyLoadQuery,
} from 'react-relay';
import { useLoaderData } from 'react-router-dom';
import AppEnvironment from '../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../../common/relay/clientschema/RelayAppSettingsTypes';
import { GetAppLevelData } from './header/components/relay/GetAppLevelData';
import { DashboardLayout2 } from './DashboardLayout2';
import { readLocalVersionAndDoComparisonWithDBVersion } from '../../common/utils/versionmanager/versionManager';
import { GetGroupClassRoomSubsTableSubscription } from './components/GetGroupClassroom';

/* Initial setup for the dashboard layout. We create custom relay client schema extensions, fire
  off a query, and a few other things
*/
export const DashboardLayout1 = () => {
  const [groupClassroomFragmentRef, setGroupClassroomFragmentRef] = useState<any>(null);

  /* Set up our relay client schema extension 
      Note that we do this before other effects, as it *might* cause strange behaviors if
      this schema does not exist when it's needed by other components
      IMPORTANT: this should run first, so the client schema is available once the mutation
        for getting trial data completes. useLayoutEffect should accomplish that
  */
  useLayoutEffect(() => {
    relayClientSchemaExtensionsSetup();
  }, []);

  // the app version timestamp from our db
  const loaderData: any = useLoaderData();
  const versionFromDB = loaderData?.timeStamp;

  useEffect(() => {
    // check if the version from the database is not available
    if (!versionFromDB) {
      // Log an error to Sentry if no version is found in the database
      Sentry.captureException(
        new Error('IMPORTANT - should never happen. No version found in db')
      );
      return;
    }

    // if versionFromDB is available, compare it with the local version
    // for more detail refer comment on `readLocalVersionAndDoComparisonWithDBVersion`
    readLocalVersionAndDoComparisonWithDBVersion(versionFromDB);
  }, [versionFromDB]);

  // App level query
  const response: any = useLazyLoadQuery(
    GetAppLevelData,
    {},
  );
  const languageLearning = response.users_connection.edges[0].node;

  // Handle invalid jwt
  if (loaderData === null) {
    /* If the data returned from routes/index => loader is null that means the user doesn't
      have a valid jwt present, or the user has triggered invalidOrExpiredRefreshToken scenario.
      In this case we will not render any component because we are redirecting user to log in page.
      IN FUTURE: We will re direct user to new react app login page from here.
    */
    return null;
  }

  return (
    <>
      {/* here we are executing our subscription for group classroom subscription  */}
      <Sentry.ErrorBoundary fallback={<div />}>
        <GetGroupClassRoomSubsTableSubscription
          setGroupClassroomFragmentRef={setGroupClassroomFragmentRef}
        />
      </Sentry.ErrorBoundary>

      <DashboardLayout2
        lnglearn={languageLearning}
        allfrags={response}
        groupClassroomFragmentRef={groupClassroomFragmentRef}
      />
    </>
  );
};

/*  Create our custom relay client schema extensions
      - Create the root record
      - Create the trialData node (no data in it yet though)
      - Create the groupClass node, and set some initial values
*/
const relayClientSchemaExtensionsSetup = () => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const root = store.getRoot();

    // root record
    let record = store.get(RelayAppSettingsVals.idVal);
    if (!record) {
      record = store.create(RelayAppSettingsVals.idVal, RelayAppSettingsVals.name);
      record.setValue(RelayAppSettingsVals.idVal, RelayAppSettingsVals.idName);
    }
    root.setLinkedRecord(record, RelayAppSettingsVals.name);

    // trialData
    let record2 = store.get(RelayAppSettingsVals.trialIdVal);
    if (!record2) {
      record2 = store.create(RelayAppSettingsVals.trialIdVal, RelayAppSettingsVals.trialType);
    }
    record.setLinkedRecord(record2, RelayAppSettingsVals.trialName);

    // groupClass. create the basic record, and set hasInterated to false. this will be set
    // to true once the user interacts with the app by clicking "join class!" button, so that
    // autoplay will work as expected
    let record3 = store.get(RelayAppSettingsVals.groupClassIdVal);
    if (!record3) {
      record3 = store.create(
        RelayAppSettingsVals.groupClassIdVal,
        RelayAppSettingsVals.groupClassType
      );
      record3.setValue(false, RelayAppSettingsVals.groupClassHasInteractedName);
    }
    record.setLinkedRecord(record3, RelayAppSettingsVals.groupClassName);

    // microphone settings. create the basic record, and set the following values and initial values
    // microphone: {
    //   current: {
    //     micId: null,
    //     title: null,
    //     isMuted: false,
    //   },
    //   all: {
    //    edges: []
    //   }
    // }
    // once the user load the groupLessons page we will check the local storage and set
    // original values instead of these initial values.

    // record for current microphone being used
    let recordforCurrentMic = store.get(RelayAppSettingsVals.currentMicIdVal);
    if (!recordforCurrentMic) {
      recordforCurrentMic = store.create(
        RelayAppSettingsVals.currentMicIdVal,
        RelayAppSettingsVals.currentMicType
      );

      recordforCurrentMic.setValue(null, RelayAppSettingsVals.currenMicrophoneIdName);
      recordforCurrentMic.setValue(null, RelayAppSettingsVals.currentMicrophoneTitleName);
      recordforCurrentMic.setValue(false, RelayAppSettingsVals.currentMicrohponeMutedName);
    }

    // record for all available microphones
    let recordforAllMic = store.get(RelayAppSettingsVals.allMicIdVal);
    if (!recordforAllMic) {
      recordforAllMic = store.create(
        RelayAppSettingsVals.allMicIdVal,
        RelayAppSettingsVals.allMicType
      );

      recordforAllMic.setValue(RelayAppSettingsVals.allMicIdVal, RelayAppSettingsVals.allMicIdName);
    }
    recordforAllMic.setLinkedRecords([], 'edges');

    // main microhpne object
    let microphoneRecord = store.get(RelayAppSettingsVals.micrphoneDetailsIdVal);
    if (!microphoneRecord) {
      microphoneRecord = store.create(
        RelayAppSettingsVals.micrphoneDetailsIdVal,
        RelayAppSettingsVals.micrphoneDetailsType
      );

      microphoneRecord.setValue(
        RelayAppSettingsVals.micrphoneDetailsIdVal,
        RelayAppSettingsVals.microphoneDetailsIdName
      );
    }

    microphoneRecord.setLinkedRecord(recordforCurrentMic, RelayAppSettingsVals.currentMicName);

    microphoneRecord.setLinkedRecord(recordforAllMic, RelayAppSettingsVals.allMicName);

    record.setLinkedRecord(microphoneRecord, RelayAppSettingsVals.microPhoneDetailsName);
  });
};

import React, { useLayoutEffect, useState } from 'react';
import {
  Grid2, Grid2Ct, Ty, Box, CardSmallColor, IcSvgList,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useOutletContext } from 'react-router-dom';
import { useFragment, useMutation } from 'react-relay';
import { GetGroupClassroomFragment } from '../../../layouts/dashboard/relay/GetGroupClassroom';
import { StuSubsCreatecheckoutlink } from './relay/StuSubsCreatecheckoutlink';
import { StuSubsCreateportalurl } from './relay/StuSubsCreateportalurl';
import { ErrorsStuSubsCreatecheckoutlinkMutation, ErrorsStuSubsCreateportalurlMutation } from './Errors/Errors';
import { ErrorComponent } from './components/ErrorsComponent';
import { GroupSubscriptionModal } from './components/GroupClassroomSubscriptionModal';
import { SmallcardMaxWidthContain } from '../common/PurchaseCommon';

export const PurchaseGroup = () => {
  // #region general

  // state to store the error code returned from backend in any api call failure
  const [errorCode, setErrorCode] = useState<null | string>(null);

  // reading the fragment from outletContext, because this is created in dashboard layout and passed
  // down so we can use it wherever we want
  const { groupClassroomSubscriptionFragmentRef }: any = useOutletContext();

  // reading the data using groupClassroomSubscriptionFragmentRef and 
  // groupClassroomSubscriptionFragment
  const groupClassroomSubscriptionData = useFragment(
    GetGroupClassroomFragment,
    groupClassroomSubscriptionFragmentRef,
  );

  // #endregion

  // #region create portal url

  // state to store the portal url
  const [portalUrl, setPortalUrl] = useState('');

  // mutation call for generate portal url, we will execute this mutation call
  // if groupcl value is equals to 1
  const [commitStuSubsCreateportalurl] = useMutation(StuSubsCreateportalurl);

  useLayoutEffect(() => {
    // we need to make sure groupClassroomSubscriptionData exist
    // before making any api call
    if (groupClassroomSubscriptionData !== null) {
      // if groupClassroomSubscriptionData.groupcl === 1 we will call the 
      // mutation call to generate portal url, because we have to open this url when user clicks
      // on the manage subscription button
      if (groupClassroomSubscriptionData.groupcl === 1) {
        commitStuSubsCreateportalurl({
          variables: {},
          onCompleted: (res: any) => {
            // making sure that the portal url exist
            // then setting it to the portalUrl state variable
            if (res?.stu_subs_createportalurl?.portalUrl) {
              setPortalUrl(res.stu_subs_createportalurl.portalUrl);

              // Important! this should never occur
            } else {
              Sentry.captureException(
                new Error("IMPORTANT! create portal link returned 200 but didn't found the checkout url in it"),
                {
                  extra: {
                    error: res
                  }
                }
              );
            }
            setErrorCode(null);
          },
          onError(error) {
            // attempt to get the error code from our backend. this will only work if the error is a
            // known, expected error
            try {
              const errorObject = JSON.parse(error.message);
              const errCode = errorObject?.extensions?.code;

              if (ErrorsStuSubsCreateportalurlMutation.TryAgain) {
                setErrorCode(errCode);
              } else if (ErrorsStuSubsCreateportalurlMutation.NotAllowed) {
                setErrorCode(errCode);
              } else {
                // IMPORTANT! this should never occur
                setErrorCode(ErrorsStuSubsCreateportalurlMutation.UnexpectedOrTimeout);
              }

              // if the error is an unexpected error then our catch block will execute
            } catch (e) {
              setErrorCode(ErrorsStuSubsCreateportalurlMutation.UnexpectedOrTimeout);
            }
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupClassroomSubscriptionData]);

  // #endregion

  // #region create checkout url

  // state for opening and closing the modal
  const [openModal, setOpenModal] = useState(false);

  // state for disabling the button if the mutation call to 
  // create checkout url is in flight
  const [isInFlight, setIsInFlight] = useState(false);

  // state to store the checkoutUrl, because we have to use the url
  // to redirect user to stripe checkout page
  const [checkoutUrl, setCheckoutUrl] = useState('');

  // mutation call for generating checkout url, we will call this mutation call only if
  // the groupClassroomSubscriptionData.groupcl === 0
  const [commitStuSubsCreatecheckoutlink] = useMutation(StuSubsCreatecheckoutlink);

  // function which will handle user clicking on Subscribe To Group Lesson button
  const handleClickSubscribeToGroupClass = () => {
    // setting this state to true so we can show the modal immediately
    setOpenModal(true);
    // setting this state to true, so we can disable the button and show loader in it
    setIsInFlight(true);
    commitStuSubsCreatecheckoutlink({
      variables: {},
      onCompleted: (res: any) => {
        // making sure that the checkout url exist
        if (res?.stu_subs_createcheckoutlink?.checkoutUrl) {
          // setting the checkout url so we can access it in modal, and redirect user to
          // stripe checkout page using this url.
          setCheckoutUrl(res.stu_subs_createcheckoutlink.checkoutUrl);
          // once, the api call gives success/failed we have to enable the button again
          setIsInFlight(false);
        } else {
          // once, the api call gives success/failed we have to enable the button again
          setIsInFlight(false);
          // IMPORTANT! this should never occur, logging here so we now our code is not
          // working as expected
          Sentry.captureException(
            new Error("IMPORTANT! create checkout link returned 200 but didn't found the checkout url in it"),
            {
              extra: {
                error: res
              }
            }
          );
        }
        setErrorCode(null);
      },
      onError(error) {
        // attempt to get the error code from our backend. this will only work if the error is a
        // known, expected error
        try {
          const errorObject = JSON.parse(error.message);
          const errCode = errorObject?.extensions?.code;

          if (ErrorsStuSubsCreatecheckoutlinkMutation.TryAgain) {
            setErrorCode(errCode);
          } else if (ErrorsStuSubsCreatecheckoutlinkMutation.NotAllowed) {
            setErrorCode(errCode);
          } else {
            // IMPORTANT! this should never occur
            setErrorCode(ErrorsStuSubsCreatecheckoutlinkMutation.UnexpectedOrTimeout);
          }
          // if the error is an unexpected error then our catch block will execute
        } catch (e) {
          setErrorCode(ErrorsStuSubsCreatecheckoutlinkMutation.UnexpectedOrTimeout);
        }
        setIsInFlight(false);
      },
    });
  };

  // #endregion

  // TODO: not sure if we need to make this better right now, but we should improve this
  //  fairly soon. the graphql subscription that gets groupcl is an app level subscription, and
  //  it is not guaranteed to have resolved when this page loads. meaning that 
  //  groupClassroomSubscriptionData.groupcl could be undefined here. we should probably have
  //  a regular graphql query and suspense one level up from this file that gets the groupcl
  //  value (in addition to the subscription), to better solve this problem
  if (groupClassroomSubscriptionData === null || groupClassroomSubscriptionData === undefined) {
    return (
      <div />
    );
  }
  if (!Object.hasOwn(groupClassroomSubscriptionData, 'groupcl')) {
    return (
      <div />
    );
  }

  return (
    <>
      <Grid2Ct>
        {/* text */}
        <Grid2 xs={12} sx={{ mt: { xs: 1, sm: 2 } }}>
          {/* text if the user is not subscribed, or subscribed */}
          {groupClassroomSubscriptionData.groupcl === 0 ? (
            <Ty>
              Join <strong>unlimited</strong> small group classes
              for <strong>$29.99/month!</strong> You, a teacher, and a few other students.
              Easily start and stop your subscription any time. Click the ?
              icon above to learn more!
            </Ty>
          ) : (
            <Ty>
              You&apos;re subscribed to group classes!
              <br /><br />
              Click the button below to manage your subscription, view payment history,
              cancel/restart, or change your billing information:
            </Ty>
          )}
        </Grid2>

        {/* error generating the portal link */}
        {(groupClassroomSubscriptionData.groupcl === 1 && errorCode !== null) && (
          <Grid2 xs={12}>
            <ErrorComponent
              errorCode={errorCode}
              groupClassroomSubscriptionData={groupClassroomSubscriptionData}
            />
          </Grid2>
        )}

        {/* subscribe button, and manage subscription button */}
        <Grid2 xs={12}>
          <SmallCardContainer>
            {/* user is not subscribed. this shows a button to open a modal */}
            {groupClassroomSubscriptionData.groupcl === 0 ? (
              <CardSmallColor
                text="Subscribe!"
                icon={IcSvgList.cart2}
                color="accentGreen1"
                hovercursor="pointer"
                onClick={handleClickSubscribeToGroupClass}
              />
            ) : (
              // user is subscribed, this is a link to their portal. we open it in a new tab
              // bc it seems it doesnt have a back button to our website? maybe we can add one?
              <CardSmallColor
                text="Manage Subscription"
                icon={IcSvgList.cart2}
                color="accentGreen1"
                hovercursor="pointer"
                onClick={() => { window.location.href = portalUrl; }}
              />
            )}
          </SmallCardContainer>
        </Grid2>
      </Grid2Ct>

      <GroupSubscriptionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        checkoutUrl={checkoutUrl}
        isInFlight={isInFlight}
        errorCode={errorCode}
        groupClassroomSubscriptionData={groupClassroomSubscriptionData}
      />
    </>
  );
};

const SmallCardContainer = ({ children }: any) => (
  <Box display="flex" justifyContent="center">
    <SmallcardMaxWidthContain>
      {children}
    </SmallcardMaxWidthContain>
  </Box>
);

import React, { Suspense } from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
// general
import { DashboardLayout1 } from '../layouts/dashboard/DashboardLayout1';
import { PreLoadedContainer } from '../common/components/pagewrapper/PreLoadedContainer';
import { generateJwtFromRefreshTokenNew } from '../utils/generateJwtFromRefreshToken';
import { PageLevelError } from '../common/components/errorfallback/PageLevelError';
import { RouteLevelError } from '../common/components/errorfallback/RouteLevelError';
// pages - home
import { Home } from '../pages/home/lessons/Home';
import { LegacyMycalendar } from '../pages/home/schedule/mycalendar';
import { LegacySendmessage } from '../pages/home/message/sendamessage';
import { TeachersDiscover } from '../pages/home/teachers/discover/TeachersDiscover';
import { TeachersProfile } from '../pages/home/teachers/individual/TeachersProfile';
// pages - practice
import { PracticeHome } from '../pages/practice/PracticeHome';
import { LegacyEasylistening } from '../pages/practice/LegacyEasylistening';
import { LegacyCurriculum } from '../pages/practice/LegacyCurriculum';
// pages - purchase (legacy)
import { PurchaseMainLayout } from '../pages/purchase/PurchaseMainLayout';
import { PurchaseHistoryHome } from '../pages/purchase/history/PurchaseHistoryHome';
import { PurchasePrivatePay } from '../pages/purchase/purchaseprivate/pay/PurchasePrivatePay';
import { LegacyTipteacher } from '../pages/purchase/tipteacher/TipTeacher';
// pages - more
import { SettingsHome } from '../pages/more/home/SettingsHome';
import { Settings } from '../pages/more/settings/Settings';
import { LegacyMyfiles } from '../pages/more/legacymyfiles/myfiles';
import { LegacyAvtest } from '../pages/more/legacyavtest/avtest';
// pages - email preferences
import { EmailPrefs } from '../pages/emailprefs';
// pages - group lessons
import { GroupSchedule } from '../pages/group/schedule/GroupSchedule';
import { GroupClassroom } from '../pages/group/classroom/GroupClassroom';
// progress
import { ProgressHome1 } from '../pages/progress/home/ProgressHome1';
// pages - test
import { PageError } from '../pages/test/pageerror/PageError';
import { sitedata } from '../utils/sitedata';
import { StripeTest } from '../pages/test/stripe/StripeTest';

export const Router = () => {
  // In v6.4, new routers were introduced that support the new data APIs.
  // It means, by using this, you can load data during rendering phase, not
  // after complete render: https://reactrouter.com/en/main/routers/picking-a-router
  // check the settings page route where we have used it.
  const router = createBrowserRouter([
    // emailprefs
    {
      path: 'emailprefs',
      element: <EmailPrefs />,
      errorElement: <RouteLevelError />,
    },

    // main app, for authed users (dashboardlayout pages)
    {
      element: <Suspense fallback=""><DashboardLayout1 /></Suspense>,
      errorElement: <RouteLevelError />,
      loader: async () => {
        // gets a jwt for the user
        // IMPORTANT: to check everywhere this is used in the app, search for "useLoaderData"
        const data = await generateJwtFromRefreshTokenNew();
        return data;
      },
      // very important. this causes the loader above to only run when the app first loads. without
      // this, the loader runs whenever the route changes, making those route changes very slow
      shouldRevalidate: ({ currentUrl }) => currentUrl.pathname === '/',
      children: [
        // TODO: what's this for?
        { path: '/', element: <Navigate to="/home" replace />, index: true },

        // home
        {
          path: 'home',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'Lessons',
                'page-studentshome',
                <Home />,
                null,
              ),
              errorElement: <PageLevelError />,
            },
            {
              path: 'myschedule',
              element: PreLoadedContainer(
                'My Schedule',
                'legacy-mycalendar',
                <LegacyMycalendar />,
                '/home',
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'messages',
              children: [
                {
                  index: true,
                  element: PreLoadedContainer(
                    'Messages',
                    'legacy-messages',
                    <LegacySendmessage />,
                    '/home',
                  ),
                  errorElement: <PageLevelError />
                },
              ]
            },
            {
              path: 'discoverteachers',
              element: PreLoadedContainer(
                'Discover Teachers',
                'page-teachersdiscover',
                <TeachersDiscover />,
                '/home',
                // this prevent default scrolling behaviour on this page. check PreLoadedContainer
                // comments for more detail
                false
              ),
              errorElement: <PageLevelError />
            },
            // teacher's profile. students get here from the home page
            {
              path: 'tchschedule',
              element: PreLoadedContainer(
                'Teacher Profile',
                'page-teachersprofileprivatelsn',
                <TeachersProfile mainRoute={2} />,
                '/home',
              ),
              errorElement: <PageLevelError />
            },
            // teacher's profile. students get here from the discover teachers page 
            {
              path: 'tchprofile',
              element: PreLoadedContainer(
                'Teacher Profile',
                'page-teachersprofile',
                <TeachersProfile mainRoute={1} />,
                '/home/discoverteachers',
              ),
              errorElement: <PageLevelError />
            },
            {
              path: sitedata.url.app.grouplsnUpcomingDt.routerPage,
              children: [
                // list of upcoming group lessons
                {
                  index: true,
                  element: PreLoadedContainer(
                    'Group Classes',
                    'group-upcoming',
                    <GroupSchedule />,
                    sitedata.url.app.grouplsnUpcomingDt.backTo,
                  ),
                  errorElement: <PageLevelError />
                },
                // group lesson classroom
                {
                  path: sitedata.url.app.groupclassroomDt.routerPage,
                  element: PreLoadedContainer(
                    'Group Classroom',
                    'group-classroom',
                    <GroupClassroom />,
                    sitedata.url.app.groupclassroomDt.backTo,
                  ),
                  errorElement: <PageLevelError />
                },
              ]
            }
          ]
        },

        // practice
        {
          path: 'practice',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'Practice',
                'page-home',
                <PracticeHome />,
                null,
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'easylistening',
              element: PreLoadedContainer(
                'Easy Listening',
                'legacy-easylistening',
                <LegacyEasylistening />,
                '/practice',
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'curriculum',
              element: PreLoadedContainer(
                'Curriculum',
                'legacy-curriculum',
                <LegacyCurriculum />,
                '/practice',
              ),
              errorElement: <PageLevelError />
            },
          ]
        },

        // purchase
        {
          path: 'purchase',
          children: [
            // purchase home page
            {
              index: true,
              element: PreLoadedContainer(
                'Purchase Home',
                'page-purchasehome',
                <PurchaseMainLayout />,
                null,
              ),
              errorElement: <PageLevelError />
            },
            // pay page
            {
              path: sitedata.url.app.purchasePayDt.routerPage,
              element: PreLoadedContainer(
                'Purchase',
                'page-purchasecheckout',
                <PurchasePrivatePay />,
                sitedata.url.app.purchasePayDt.backTo,
              ),
              errorElement: <PageLevelError />
            },
            // tip teacher
            {
              path: sitedata.url.app.purchaseTipDt.routerPage,
              element: PreLoadedContainer(
                'Tip Teacher',
                'legacy-tipteacher',
                <LegacyTipteacher />,
                sitedata.url.app.purchaseTipDt.backTo,
              ),
              errorElement: <PageLevelError />
            },
            // purchase history
            {
              path: sitedata.url.app.purchaseHistoryDt.routerPage,
              element: PreLoadedContainer(
                'Purchase History',
                'page-purchasehistory',
                <PurchaseHistoryHome pageSize={6} />,
                sitedata.url.app.purchaseHistoryDt.backTo,
              ),
              errorElement: <PageLevelError />
            },
          ]
        },

        // progress
        {
          path: sitedata.url.app.progressHomeDt.routerPage,
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'Progress',
                'progress-home',
                <ProgressHome1 />,
                sitedata.url.app.progressHomeDt.backTo,
              ),
              errorElement: <PageLevelError />
            },
          ]
        },

        // more
        {
          path: 'more',
          children: [
            {
              index: true,
              element: PreLoadedContainer(
                'More',
                'settings-home',
                <SettingsHome />,
                null,
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'myfiles',
              element: PreLoadedContainer(
                'My Files',
                'legacy-files',
                <LegacyMyfiles />,
                '/more',
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'avtest',
              element: PreLoadedContainer(
                'AV Test',
                'legacy-avtest',
                <LegacyAvtest />,
                '/more',
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'mysettings',
              element: PreLoadedContainer(
                'Settings',
                'new-settings-page',
                <Settings />,
                '/more',
              ),
              errorElement: <PageLevelError />
            },
          ]
        },

        // test
        {
          path: 'test',
          children: [
            // test page level error
            {
              path: 'pageerror',
              element: PreLoadedContainer(
                'Page Level Error',
                'page-level-error',
                <PageError />,
                null,
              ),
              errorElement: <PageLevelError />
            },
            {
              path: 'stripe-page',
              element: PreLoadedContainer(
                'Stripe Page',
                'stripe-page',
                <StripeTest />,
                null,
              ),
              errorElement: <PageLevelError />
            }
          ]
        }
      ],
    },

    // redirect to the home page if the user attempts to access a non-existent page
    {
      path: '*',
      children: [
        { path: '*', element: <Navigate to="/home" replace /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

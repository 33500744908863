import React from 'react';
import {
  Grid2, Grid2Ct, Ty, CardStandard, Link, IcSvgList,
} from '@languageconvo/wcl';
import {
  NavLink
} from 'react-router-dom';
import { sitedata } from '../../utils/sitedata';

export const PracticeHome = () => {
  // #region setup data

  // click to go to the easylistening page
  const clickableTypeObj = {
    to: sitedata.url.app.practiceEasylistening,
    component: NavLink,
  };

  // #endregion

  return (
    <Grid2Ct columns={20}>
      {/* card - easylistening */}
      <Grid2 xs={20} sm={16} smOffset={2} md={14} mdOffset={3} xl={10} xlOffset={5}>
        <CardStandard
          clickableLink1={{ ...clickableTypeObj }}
          titleText="EasyListening Practice"
          titleIcon={IcSvgList.music1}
          color="accentPurple1"
        >
          {/* we wrap the entire children/contents in a link so that it's also clickable, making
          it easy for the user to click almost anywhere in the card */}
          <Link
            to={sitedata.url.app.practiceEasylistening}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty>Type words, phrases, even entire paragraphs, and listen to how it
                  sounds in the language you are learning!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </Grid2>

      {/* more coming soon */}
      <Grid2 xs={20} sm={16} smOffset={2} md={14} mdOffset={3} xl={10} xlOffset={5}>
        <CardStandard
          titleText="More Coming Soon!"
          titleIcon={IcSvgList.award1}
          titleIconRight={0}
          color="accentGreen1"
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              <Ty>
                Along with adding more languages for group classes,
                our #1 priority the next few months is bringing you new, free practice
                features to use alongside your lessons/classes. The next is coming
                in just a few weeks, and we&apos;ll release more every month!
              </Ty>
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

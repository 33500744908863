import React, { Suspense, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import {
  Grid2Ct, Grid2, Ty, Ic, CardStandard, IcSvgList, Dialog, Link,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { PurchaseChoosePage1 } from './purchaseprivate/choose/PurchaseChoosePage1';
import { ChoosePackageLoading } from './purchaseprivate/choose/PurchaseChoosePage2';
import { PrivateCredits } from './privatecredits/PrivateCredits';
import { PurchaseGroup } from './purchasegroup/PurchaseGroup';
import { sitedata } from '../../utils/sitedata';
import { PayPageLoading } from './common/PayPageLoading';

export const PurchaseMainLayout = () => {
  // #region general

  const [paypageLoading, setPaypageLoading] = useState(false);

  // link to purchase history page
  const urlPurchasehistory = sitedata.url.app.purchaseHistoryDt.pathFull;
  const clickableTypeObjMessagesPurchasehistory = {
    to: urlPurchasehistory,
    component: NavLink,
  };

  // link to teacher tip page
  const urlTipteacher = sitedata.url.app.purchaseTip;
  const clickableTypeObjMessagesTipteacher = {
    to: urlTipteacher,
    component: NavLink,
  };

  // if the user just successfully subscribed to group classes via stripe, they are returned
  // to this purchase page with a query string: /purchase?subs=random_text_from_stripe
  // in this case we'll show them a success message
  const [searchParams] = useSearchParams();
  const subSuccess = searchParams.get('subs');
  let userSubscribedSuccessfully = false;
  if (subSuccess && subSuccess.length > 3) {
    userSubscribedSuccessfully = true;
  }

  // #endregion

  // #region modals

  // modal for info about purchasing private lessons
  const [modalStatePrivatelsn, setModalStatePrivatelsn] = useState<boolean>(false);
  const handleModalOpenPrivatelsn = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrivatelsn(true);
  };
  const handleModalClosePrivatelsn = () => {
    setModalStatePrivatelsn(false);
  };

  // modal for info about purchasing group classes
  const [modalStateGroup, setModalStateGroup] = useState<boolean>(false);
  const handleModalOpenGroup = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateGroup(true);
  };
  const handleModalCloseGroup = () => {
    setModalStateGroup(false);
  };

  // modal for info about private lesson credits
  const [modalStatePrivatelsncred, setModalStatePrivatelsncred] = useState<boolean>(false);
  const handleModalOpenPrivatelsncred = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrivatelsncred(true);
  };
  const handleModalClosePrivatelsncred = () => {
    setModalStatePrivatelsncred(false);
  };

  // #endregion

  if (paypageLoading) {
    return (
      <PayPageLoading />
    );
  }

  // layout of the purchase home page
  return (
    <>
      {/* thank you message if the user just successfully subscribed to group classes */}
      {userSubscribedSuccessfully === true && (
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} mdOffset={3}>
            <CardStandard
              titleText="Thank you!"
              titleIcon={IcSvgList.gift1}
              color="accentGreen1"
              titleIconRight={0}
            >
              <Grid2Ct sx={{ mt: 1 }}>
                <Grid2 xs={12}>
                  <Ty>
                    You successfully subscribed! This page will automatically update in
                    just a moment, you should see a &quot;Manage Subscription&quot; button in the
                    Purchase Group Classes section. If that has not updated within an hour or
                    so, please let our customer service team know.
                  </Ty>
                </Grid2>
              </Grid2Ct>
            </CardStandard>
          </Grid2>
        </Grid2Ct>
      )}

      {/* purchase private lessons, purchase group classes */}
      <Grid2Ct>
        {/* purchase private lessons
            on sm and larger screens, we make this the same height as the group lessons box using
            display flex. on xs, they are not the same height
        */}
        <Grid2 xs={12} md={6} sx={{ display: { xs: 'block', sm: 'flex' } }}>
          <CardStandard
            titleText="Purchase Private Lessons"
            titleIcon={IcSvgList.teacher5}
            color="accentPurple1"
            titleIconRight={2}
            onClickRightIcon={handleModalOpenPrivatelsn}
            // width 100% is needed when using display flex
            cp={{ sx: { width: '100%' } }}
          >
            <Suspense fallback={<ChoosePackageLoading />}>
              <PurchaseChoosePage1 setPaypageLoading={setPaypageLoading} />
            </Suspense>
          </CardStandard>
        </Grid2>

        {/* purchase group classes subscription
            on sm and larger screens, we make this the same height as the group classes box using
            display flex. on xs, they are not the same height
        */}
        <Grid2 xs={12} md={6} sx={{ display: { xs: 'block', sm: 'flex' } }}>
          <CardStandard
            titleText="Purchase Group Classes"
            titleIcon={IcSvgList.group1}
            color="accentBlue1"
            titleIconRight={2}
            onClickRightIcon={handleModalOpenGroup}
            // width 100% is needed when using display flex
            cp={{ sx: { width: '100%' } }}
          >
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryGroupSubscription />}>
              <PurchaseGroup />
            </Sentry.ErrorBoundary>
          </CardStandard>
        </Grid2>
      </Grid2Ct>

      {/* private lesson credits remaining, purchase history link, tip teacher link */}
      <Grid2Ct sx={{ mt: 1 }}>
        {/* credits available
            note: we do *not* make this equal height to history link, because the user could have
            many different languages which makes this card very tall
         */}
        <Grid2 xs={12} md={6}>
          <CardStandard
            titleText="Private Lessons Remaining"
            titleIcon={IcSvgList.coin1}
            titleIconRight={2}
            color="accentYellow1"
            onClickRightIcon={handleModalOpenPrivatelsncred}
          >
            {/* we wrap this in an error boundary; it's calling old php code that is pretty
                slow, so it's definitely possible we'll get errors (especially timeouts) and
                we don't want the entire page to error in that case
             */}
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryPrivateCredits />}>
              <PrivateCredits />
            </Sentry.ErrorBoundary>
          </CardStandard>
        </Grid2>

        {/* purchase history link and tip teacher link */}
        <Grid2 xs={12} md={6}>
          <Grid2Ct>
            {/* purchase history link */}
            <Grid2 xs={12}>
              <CardStandard
                titleText="Purchase History"
                titleIcon={IcSvgList.knowledge2}
                color="accentPurple1"
                clickableLink1={{ ...clickableTypeObjMessagesPurchasehistory }}
              >
                <Link
                  to={urlPurchasehistory}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12} display="flex">
                      <Ty removeMb>View your complete purchase history</Ty>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>

            {/* tip teacher link */}
            <Grid2 xs={12}>
              <CardStandard
                titleText="Tip Teacher"
                titleIcon={IcSvgList.teacher4}
                color="accentGreen1"
                cp={{ sx: { width: '100%' } }}
                clickableLink1={{ ...clickableTypeObjMessagesTipteacher }}
              >
                <Link
                  to={urlTipteacher}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12} display="flex">
                      <Ty removeMb>Never expected, always greatly appreciated!</Ty>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>

      {/* private lesson info modal */}
      <Dialog
        isOpen={modalStatePrivatelsn}
        onClose={handleModalClosePrivatelsn}
        width="lg"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12} sm={10} smOffset={1}>
            <Ty v="h2New">
              <Ic iconName="square-info" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;What do we mean by &quot;private lessons&quot;?
            </Ty>
            <Ty>
              Private lessons are just you and a teacher. You can, if you want, invite other people
              that you know...at no extra cost! But no other students will be there, it is a private
              lesson just for you.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="bag-shopping" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;If I buy for example a 10 hour package, can I schedule lessons of different lengths?
            </Ty>
            <Ty>
              Definitely. No matter which package you buy, you can schedule lessons that are 30
              minutes long, or 1 hour long, or 1.5 hours, etc. Scheduling is extremely flexible and
              you can schedule each lesson differently.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="calendar-days" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;How long do I have to use the lessons?
            </Ty>
            <Ty>
              You have an entire year to use your lessons, so plenty of time! If after 1 year
              you have not used them all yet, you can reactivate them by purchasing a new package.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* modal, group classes */}
      <Dialog
        isOpen={modalStateGroup}
        onClose={handleModalCloseGroup}
        width="lg"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12} sm={10} smOffset={1}>
            <Ty v="h2New">
              <Ic iconName="square-info" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;How do group classes work?
            </Ty>
            <Ty>
              Group classes include you, a teacher, and a small group of other students.
              Most classes will consist of two parts: for the first half of the class your teacher
              will teach a topic, then the second half of the class you practice conversing with
              the other students, using what you learned! A maximum of 8 students can be in a class
              but that gets split up into two separate conversation groups; being in a small group
              allows you more time to practice using the language. Your teacher will jump between
              the two groups to help facilitate conversation as needed.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="users" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;Join Unlimited Classes
            </Ty>
            <Ty>
              When you subscribe, you can join unlimited classes! Yes, unlimited. The only
              &quot;catch&quot; is that you cannot share your account/subscription with anyone
              else. Your subscription is for you only. If you are setting up subscriptions for
              family members, every person does need to have their own subscription.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="bag-shopping" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;How can I cancel the subscription?
            </Ty>
            <Ty>
              Once you are subscribed, you will see a button right on this page (where you see the
              &quot;Subscribe&quot; button now) that will let you cancel. You do NOT have to email
              us or call us or beg us, canceling your subscription is easy. Restarting your
              subscription later on is easy too.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="calendar-days" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;When are classes? How often do they occur?
            </Ty>
            <Ty>
              This will vary based on how many subscribers we have; as the number of subscribers
              increases obviously we&apos;ll add more and more classes. Please go to the group
              classes page (which you can get to from the main &quot;Lessons&quot; page) to see
              what is coming up over the next few days! We do plan on having multiple classes
              every single day, even as we&apos;re just getting this new feature started.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* private lesson credits */}
      <Dialog
        isOpen={modalStatePrivatelsncred}
        onClose={handleModalClosePrivatelsncred}
        width="xs"
        color="accentYellow1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              If you have purchased private lessons, here you&apos;ll be able to see how many
              lesson hours you have remaining!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// if the private lessons query fails, we'll show this. that query is old php code so is more
// brittle than most
const ErrorBoundaryPrivateCredits = () => (
  <Grid2Ct>
    <Grid2
      xs={12}
      sm={10}
      smOffset={1}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Ty align="center">
        <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
        went wrong. Please reload the page, and if you continue seeing this error *please*
        let us know!
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// if the group subscriptions gave some error we will show this boundary in that card
const ErrorBoundaryGroupSubscription = () => (
  <Grid2Ct>
    <Grid2
      xs={12}
      sm={10}
      smOffset={1}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Ty align="center">
        <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
        went wrong. Please reload the page, and if you continue seeing this error *please*
        let us know!
      </Ty>
    </Grid2>
  </Grid2Ct>
);
